import React, { useCallback, useMemo } from "react";
import { BASE_COLUMN_TYPES, ColumnDataMap, REPORT_TOOLTIP_TARGET_ID } from "../config/types";
import { CellProps } from "./Cell";
import { colors, Typography } from "@commonsku/styles";
import { useTooltipAttributes } from "../../../../hooks";

type TColumn = typeof BASE_COLUMN_TYPES.Link;
const LinkCell = ({
  column,
  row,
  className,
  style = {},
  textStyle = {},
}: CellProps<TColumn>) => {
  const { accessorKey, dataTransform, linkAction } = column;
  const { href, text, tooltipText, tooltipOptions } = useMemo<ColumnDataMap<TColumn>>(() => {
    if (!dataTransform) {
      throw new Error("LinkCell requires dataTransform");
    }

    return { ...dataTransform(row.original[accessorKey]) };
  }, [accessorKey, dataTransform, row.original]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (linkAction) {
        event.preventDefault();
        linkAction(row.original[accessorKey]);
      }
    },
    [accessorKey, linkAction, row.original],
  );

  const hasLink = !!href || !!linkAction;
  const tooltipAttributes = useTooltipAttributes(
    tooltipText ?? (hasLink ? text : null),
    REPORT_TOOLTIP_TARGET_ID,
    tooltipOptions
  );

  return (
    <div className={className} style={style} {...tooltipAttributes}>
      {(!!href || !!linkAction) ? (
        <Typography.Span
          as="a"
          style={{
            display: "block",
            alignContent: "center",
            color: style.color,
            ...textStyle,
          }}
          href={href}
          target="_blank"
          rel="noreferrer"
          onClick={handleClick}
        >
          {text}
        </Typography.Span>
      ) : (
        <Typography.Span
          style={{
            display: "block",
            alignContent: "center",
            color: colors.neutrals.bodyText,
            ...textStyle,
          }}
        >
          {text}
        </Typography.Span>
      )}
    </div>
  );
};

export default LinkCell;
